<template>
    <v-container grid-list-xs class="pa-16">
        <v-row>
        <v-col class="col-4" v-show="this.$route.params.type == 'Recent Update'">
            <v-select
              :items="returnDept"
              v-model="deptFilter"
              label="Select Department / Product"
              class="mr-4 col"
              hide-details
              outlined
              style="width: 250px;"
              dense
              >
            </v-select>
            <v-tabs vertical v-model="tab" class="mt-4" style="width: 250px;" v-if="$route.params.type == 'Recent Update'">
                <v-tab v-for="logsItem in filterItemsLogs" :key="logsItem"  active-class="text-primary primary lighten-5">
                    {{logsItem}}
                </v-tab>
            </v-tabs>
        </v-col>
        <v-col class="col px-8 text-center'">
            <div class="text-h4 font-weight-bold text-center">{{$route.params.type == 'Recent Update' ? $route.params.type : 'Top Risk Transpired'}} 
                <span class="grey--text" v-show="$route.params.type == 'Recent Update'">({{deptFilter}})

                </span>
                
            </div>
            <div>
                <v-card v-for="(logs,idx) in returnPagination" :key="logs['.key']" class="my-4 mx-auto"  style="width:400px;">
                <v-list-item v-if="$route.params.type == 'Recent Update'" >
                    <v-list-item-avatar>
                    <v-img :src="logs.photoURL"></v-img>
                    </v-list-item-avatar>
                
                    <v-list-item-content>
                    <v-list-item-title v-html="logs.action" class="grey--text text--darken-2"></v-list-item-title>
                    <v-list-item-subtitle v-html="logs.email" class="grey--text text--darken-1"></v-list-item-subtitle>
                    <v-list-item-subtitle v-html="$moment(logs.timestamp.toDate()).format('LLLL')" class="grey--text text--darken-1 text-caption mt-2"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                    <v-list-item-avatar>
                    {{idx+1}}
                    </v-list-item-avatar>
                
                    <v-list-item-content>
                    <v-list-item-title v-html="logs.RiskName" class="grey--text text--darken-2"></v-list-item-title>
        
                    <v-list-item-subtitle v-html="$moment(logs.timestamp.toDate()).format('LLLL')" class="grey--text text--darken-1 text-caption mt-2"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>                  
                </v-card>    
            
            </div>
            <v-pagination
                v-show="returnPageLength !== 0 && $route.params.type == 'Recent Update'"
                v-model="page"
                :length="returnPageLength"
                :total-visible="7"
            ></v-pagination>
        </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        deptFilter: 'SYSDEV',
        tab: 0,
        page: 1,
        filterItemsLogs: ['ORMP','PRMP','KRI','RAEME']
    }),

    firestore(){
        return {
            Departments: this.$db.collection('Departments'),
            Product: this.$db.collection('Product'),
            ReportedRisk: this.$db.collection('ReportedRisk').orderBy('timestamp','desc'),    
            ActivityLogs: this.$db.collection('ActivityLogs').where('module','in',['ORMP','KRI','RAEME','PRMP']).orderBy('timestamp','desc'),        
        }
    },

    computed:{
        returnDept(){
            let db = this.Departments.map(a=>{
                return  a.departmentName
            })

            let product = this.Product.map(a=>{
                return a.Product
            })
        
            let concat = [...db,...product]

            return concat.filter(a=>{
                return a !== 'RISK MANAGEMENT TEAM'
            })
        },
        returnPageLength(){
            let lengthList = this.returnList.length
            let modulo = lengthList / 10
            console.log(lengthList,'leng');
            console.log(modulo,'modulo');

            let page = Number.isInteger(modulo) ? modulo : parseInt(modulo) + 1
            
            return page
        },
        returnPagination(){
            try {
                let prev = this.page == 1 ? 0 : (this.page - 1) * 10
                let next = this.page * 10
                return this.returnList.slice(prev,next)                
            } catch (error) {
                console.log(error,'error');
                return this.returnList
            }
        },
        returnList(){
            if(this.$route.params.type == 'Recent Update'){
                return this.returnLogs
            } else {
                return this.returnTranspiredRisk
            }
        },
        returnTranspiredRisk(){
            let risks = this.$lodash.uniqBy(this.ReportedRisk,'RiskName')
            return risks.slice(0,4)
        },
        returnLogs(){
            let user = this.$store.getters['useraccount/isAuthenticated']
            let tabSelected = this.filterItemsLogs[this.tab]

            let isPRMP = tabSelected == 'PRMP' ? true : false
            

            let mapped = this.ActivityLogs.map(a=>{
                return {
                    ...a,
                    department: a.department == 'RISK MANAGEMENT TEAM' || isPRMP ? a.dataOwner : a.department
                }
            })

            
            if(user.department !== 'RISK MANAGEMENT TEAM'){
                console.log('here');
                return mapped.filter(a=>{
                    return a.department == user.department && a.module == tabSelected
                })
            }


            return mapped.filter(a=>{
                return a.department == this.deptFilter && a.module == tabSelected
            })
        },
    },

    methods: {

    }
    
}
</script>
<style scoped>

div .v-tab  {
    justify-content: left;
}

</style>